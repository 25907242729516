<template>
	<div class="mhome">
		<div class="mhome-wrap">
			<div class="mrefund-apply">
				<div class="mapply-group">
					<div class="item">
						<div class="item-label">{{ $t('mobile.formItem.logisticsNo') }}<em>*</em></div>
						<div class="item-block">
							<el-input v-model="refundForm.logistics_no" maxlength="30" :placeholder="$t('mobile.placeholder.logisticsNo')"></el-input>
							<i class="el-icon-arrow-right"></i>
						</div>
					</div>
					<div class="item">
						<div class="item-label">{{ $t('mobile.formItem.logisticsCompany') }}<em>*</em></div>
						<div class="item-block" @click="handlePopShow">
							<span v-if="refundForm.logistics_company">{{ refundForm.logistics_company }}</span>
							<template v-else class="gray">{{$t('mobile.placeholder.logisticsCompany')}}</template>
							<i class="el-icon-arrow-right"></i>
						</div>
					</div>
					<div class="item">
						<div class="item-label">{{ $t('mobile.formItem.contactNumber') }}</div>
						<div class="item-block">
							<el-input v-model="refundForm.mobile" maxlength="30" :placeholder="$t('mobile.placeholder.contactNumber')"></el-input>
							<i class="el-icon-arrow-right"></i>
						</div>
					</div>
				</div>
				<div class="mapply-remark">
					<div class="remark-tit">{{ $t('mobile.refund.replenishText') }}</div>
					<div class="remark-box">
						<el-input v-model="refundForm.description" type="textarea"
							:autosize="{ minRows: 2, maxRows: 10 }"
							:placeholder="$t('mobile.placeholder.logisticsDesc')" maxlength="200" show-word-limit>
						</el-input>
						<ul class="upload-list">
							<li v-for="(item, index) in refundForm.img_urls" :key="index">
								<img :src="item" alt="">
								<i class="btn-close el-icon-error" @click="handleRemoveImg(index)"></i>
							</li>
							<li v-if="refundForm.img_urls.length < 6">
								<el-upload :multiple="true" :show-file-list="false"
									:http-request="(data) => { httpRequest(data.file) }" class="upload-box" action="#"
									v-loading="loading">
									<i class="el-icon-camera"></i>
									<div class="text">
										<p>{{ $t('mobile.actions.uploadVoucher') }}</p>
										<p>({{ $t('mobile.actions.maxLength6') }})</p>
									</div>
								</el-upload>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="mrefund-block"></div>
			<div class="mrefund-bottom">
				<div class="btn" @click="handleSubmit">{{ $t('mobile.actions.submit') }}</div>
			</div>
			<!-- 弹窗-物流公司 start -->
			<div class="mpop" :class="{ 'mpop-show': isPopShow }">
				<div class="mpop-bg" @click="handlePopClose"></div>
				<div class="mpop-body">
					<div class="mpop-close" @click="handlePopClose"><i class="el-icon-close"></i></div>
					<div class="mpop-head">{{ $t('mobile.pop.logisticsCompany') }}</div>
					<div class="mpop-main mpop-logistics">
						<div class="mpop-group">
							<el-radio-group v-model="popLogisticsCheck">
								<el-radio v-for="(item, index) in logisticsList" :key="index" :label="JSON.stringify(item)">{{ item.name }}</el-radio>
							</el-radio-group>
						</div>
					</div>
					<div class="mpop-bottom">
						<div class="btn" @click="handleLogistics">{{ $t('mobile.actions.confirm') }}</div>
					</div>
				</div>
			</div>
			<!-- 弹窗-物流公司 end -->
		</div>
	</div>
</template>

<script>
import { OSSUpload } from "@/utils/upload"
import { fetchRefundInfo, fetchLogistics, refundLogistics } from "@/api/mobile/order"

export default {
	data() {
		return {
			refundInfo: {},
			refundForm: {
				logistics_no: '',
				logistics_code: '',
				logistics_company: '',
				mobile: '',
				img_urls: [],
				description: ''
			},
			loading: false,
			isPopShow: false,
			popLogisticsCheck: '',
			logisticsList: []
		}
	},
	created() {
		this.getRefundInfo()
	},
	methods: {
		getRefundInfo() {
			fetchRefundInfo({ apply_refund_id: this.$route.params.id }).then(response => {
				this.refundInfo = response.data || null
				if(this.refundInfo.goods_status ===3 && this.refundInfo.apply_refund_info.step ===2) {
					this.getLogisticsList()
				} else {
					this.$router.go(-1)
				}
			}).catch(() => {
				this.$router.go(-1)
			})
		},
		getLogisticsList() {
			fetchLogistics({ uuid: this.$store.getters.uuid }).then(response => {
				this.logisticsList = response.data.deliverys
			})
		},
		handlePopShow() {
			this.isPopShow = true
		},
		handlePopClose() {
			this.isPopShow = false
		},
		handleLogistics() {
			const check = JSON.parse(this.popLogisticsCheck)
			this.refundForm.logistics_code = check.code
			this.refundForm.logistics_company = check.name
			this.isPopShow = false
		},
		async httpRequest(file) {
			const isType = ['image/jpeg', 'image/png'].includes(file.type);
			const isSize = file.size / 1024 / 1024 < 3;
			if (!isType) {
				this.$message({
					message: this.$t('mobile.errorMsg.uploadType'),
					customClass: 'wap-message',
					type: 'error'
				});
				return false;
			}
			if (!isSize) {
				this.$message({
					message: this.$t('mobile.errorMsg.uploadSize3M'),
					customClass: 'wap-message',
					type: 'error'
				});
				return false;
			}
			this.loading = true;
			const { url } = await OSSUpload(file, 'other');
			if (url) {
				this.refundForm.img_urls.push(url)
			}
			this.loading = false;
		},
		handleRemoveImg(index) {
			this.refundForm.img_urls.splice(index, 1)
		},
		handleSubmit() {
			if (this.refundForm.logistics_no === '') {
				this.$message({
					message: this.$t('mobile.errorMsg.logisticsNo'),
					customClass: 'wap-message',
					type: 'error'
				});
				return false
			}
			if (this.refundForm.logistics_company === '') {
				this.$message({
					message: this.$t('mobile.errorMsg.logisticsCompany'),
					customClass: 'wap-message',
					type: 'error'
				});
				return false
			}
			const params = {
				...this.refundForm,
				apply_refund_id: this.$route.params.id
			}
			refundLogistics(params).then(() => {
				this.$router.go(-1)
				this.$message({
					message: this.$t('mobile.successMsg.submit'),
					customClass: 'wap-message',
					type: 'success'
				});
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.mrefund-apply {
	padding: 0.32rem 0.256rem;

	.mapply-group {
		border-radius: 0.32rem;
		padding: 0 0.384rem;
		background-color: #ffffff;
		margin-bottom: 0.32rem;

		.item {
			display: flex;
			justify-content: space-between;
			padding: 0.192rem 0;
			line-height: 0.896rem;
			border-bottom: 1px solid #e5e5e5;

			.item-label {
				font-size: 0.416rem;

				em {
					color: #ff5000;
				}
			}


			.item-block {
				text-align: right;
				font-size: 0.416rem;
				color: #999999;

				span {
					color: #333333;
				}

				::v-deep .el-input {
					width: 6.4rem;

					.el-input__inner {
						height: 0.896rem;
						line-height: 0.896rem;
						padding: 0;
						text-align: right;
						border: none;
						font-size: 0.416rem;

						&::-webkit-input-placeholder {
							color: #999999;
						}

						&:-ms-input-placeholder {
							color: #999999;
						}

						&::placeholder {
							color: #999999;
						}
					}
				}
			}

			&:last-child {
				border-bottom: none;
			}

			.item-input {
				height: 0;
				overflow: hidden;
			}
		}
	}

	.mapply-remark {
		border-radius: 0.32rem;
		background-color: #ffffff;
		padding: 0.48rem 0.384rem;

		.remark-tit {
			font-size: 0.416rem;
			font-weight: 600;
			height: 0.544rem;
			line-height: 0.544rem;
			margin-bottom: 0.48rem;
		}

		.remark-box {
			border-radius: 0.256rem;
			background-color: #f7f7f7;

			::v-deep .el-textarea {
				font-size: 0.416rem;

				.el-textarea__inner {
					padding: 0.16rem 0.384rem;
					border-color: transparent;
					background-color: transparent;
					resize: none;
				}

				.el-input__count {
					bottom: 0.16rem;
					right: 0.384rem;
					line-height: 1em;
					font-size: 0.352rem;
					background-color: transparent;
				}
			}

			.upload-list {
				padding: 0.32rem 0.384rem 0.48rem;

				li {
					margin-right: 0.384rem;
					width: 2.368rem;
					height: 2.368rem;
					display: inline-block;
					vertical-align: top;
					position: relative;

					.upload-box {
						width: 100%;
						height: 100%;

						::v-deep .el-upload {
							color: #999999;
							width: 100%;
							height: 100%;
							display: flex;
							align-items: center;
							justify-content: center;
							flex-flow: column;
							box-sizing: border-box;
							border: 1px dashed #bdbdbd;

							i {
								font-size: 0.832rem;
							}

							.text {
								font-size: 0.352rem;
								text-align: center;
								line-height: 0.448rem;
							}
						}
					}

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}

					.btn-close {
						top: -0.288rem;
						right: -0.288rem;
						color: #888888;
						font-size: 0.64rem;
						position: absolute;
					}
				}
			}
		}
	}
}

.mrefund-block {
	height: 1.664rem;
}

.mrefund-bottom {
	width: 100%;
	left: 0;
	bottom: 0;
	box-sizing: border-box;
	padding: 0.256rem 0.384rem;
	position: absolute;
	background-color: #ffffff;

	.btn {
		width: 100%;
		height: 1.152rem;
		line-height: 1.152rem;
		text-align: center;
		border-radius: 0.576rem;
		font-size: 0.448rem;
		color: #ffffff;
		background: linear-gradient(to right, #ee7e32, #f25528);
	}
}
.mpop-logistics {
	height: 9.6rem;
	overflow-y: auto;
}
.mpop-group {
	padding: 0.256rem 0.384rem;

	::v-deep .el-radio-group {
		display: block;

		.el-radio {
			display: block;
			margin-right: 0;
			padding: 8px 0;

			.el-radio__inner {
				width: 0.512rem;
				height: 0.512rem;
			}

			.el-radio__inner:after {
				width: 0.192rem;
				height: 0.192rem;
			}

			.el-radio__label {
				color: #333333;
				font-size: 0.416rem;

				span {
					color: #999999;
					margin-left: 0.192rem;
					font-size: 0.384rem;
				}
			}
		}
	}
}
</style>
